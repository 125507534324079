@import '../../../../scss/theme-bootstrap';

.product-smart-gift-overlay {
  margin-bottom: 30px;
  @include breakpoint($medium-up) {
    margin-bottom: 40px;
  }
  &__header-container {
    h2 {
      background-color: $color-off-black;
      color: $white;
      padding: 15px;
      text-align: center;
    }
    p {
      font-size: 16px;
      text-align: center;
      margin: 30px auto 20px;
      font-style: italic;
      @include breakpoint($medium-up) {
        font-size: 18px;
        margin-top: 40px;
        width: 70%;
      }
    }
  }
  &__section {
    margin-top: 30px;
    font-size: 16px;
    padding: 0 20px;
    h4 {
      font-weight: bold;
    }
    @include breakpoint($medium-up) {
      font-size: 18px;
      margin-top: 40px;
    }
  }
  &__colorbox {
    &#colorbox {
      z-index: 9999999;
      background-color: $white;
    }
    #cboxLoadedContent {
      padding: 0;
    }
    #cboxClose {
      color: $white;
    }
    #cboxClose:before {
      width: 25px;
      height: 25px;
      line-height: 1;
    }
  }
}
